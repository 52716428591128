<template>
  <v-container class="mt-10">
    <section name="content" class="text-page-border">
      <section>
        <v-row>
          <v-col>
            <h1 class="jlu-blue mb-4 text-h2">
              Partner
            </h1>
            <p class="text-justify">
              {{ $t("partner.main-desc") }}
            </p>
          </v-col>
        </v-row>
      </section>
      <section v-for="(partner, i) in partners" :key="i">
        <v-row class="my-16">
          <v-col cols="12" md="3" align-self="center">
            <v-img
              max-width="300px"
              :src="require(`../../assets/img/${partner.image}`)"
              class="mx-auto"
            ></v-img>
          </v-col>
          <v-col align-self="center">
            <h1 class="text-h4 jlu-blue mb-4">
              {{ $t(partner.headline) }}
            </h1>
            <p class="text-justify">
              <span v-html="$t(partner.text)"></span>
            </p>
          </v-col>
        </v-row>
      </section>
    </section>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      partners: [
        {
          headline: "partner.mii",
          text: "partner.mii-desc",
          image: "MII_partner.png",
        },
        {
          headline: "MIRACUM",
          text: "partner.miracum-desc",
          image: "miracum_partner.png",
        },
        {
          headline: "partner.jlu-imi",
          text: "partner.jlu-imi-desc",
          image: "jlu.png",
        },
        {
          headline: "partner.ukgm-giessen",
          text: "partner.ukgm-giessen-desc",
          image: "ukgm_logo.png",
        },
        {
          headline: "partner.thm-ges",
          text: "partner.thm-ges-desc",
          image: "thm_logo_ges.png",
        },
        {
          headline: "partner.dic",
          text: "partner.dic-desc",
          image: "DIZ.svg",
        },
      ],
    };
  },
};
</script>
