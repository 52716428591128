import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Carousel3d from "vue-carousel-3d";
import TopScroller from "./components/TopScroller";
import DownloadCard from "./components/DownloadCard";
import i18n from "./plugins/i18n";
import CountryFlag from "vue-country-flag";

Vue.config.productionTip = false;
Vue.use(Carousel3d);
Vue.component("country-flag", CountryFlag);
Vue.component("top-scroller", TopScroller);
Vue.component("download-card", DownloadCard);

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
