var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('section',{staticClass:"text-page-border"},[_c('h1',{staticClass:"error--text my-5",class:{
        'text-h5': _vm.$vuetify.breakpoint.smAndDown,
        'text-h3': _vm.$vuetify.breakpoint.mdAndUp,
      }},[_vm._v(" "+_vm._s(_vm.$t("impressum.title"))+" ")]),_c('h1',{class:{
        'text-h4': _vm.$vuetify.breakpoint.smAndDown,
        'text-h3': _vm.$vuetify.breakpoint.mdAndUp,
      }},[_vm._v(" "+_vm._s(_vm.$t("impressum.disclosure"))+" ")]),_c('h5',{staticClass:"text-h4 pt-10"},[_vm._v(" "+_vm._s(_vm.$t("impressum.created-by"))+" ")]),_c('p',[_vm._v(" Sascha Seibel "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("impressum.IMI"))+" ")]),_c('h3',{class:{
        'text-h5 pt-7': _vm.$vuetify.breakpoint.smAndDown,
        'text-h4 pt-16': _vm.$vuetify.breakpoint.mdAndUp,
      }},[_vm._v(" "+_vm._s(_vm.$t("home.dicfooter"))+" ")]),_c('p',[_vm._v("c/o "+_vm._s(_vm.$t("impressum.IMI")))]),_c('p',[_c('strong',[_vm._v("Tel.")]),_vm._v(" +49 (0) 641 99 41393")]),_c('p',[_c('strong',[_vm._v("E-Mail:")]),_vm._v(" Achim.Michel-Backofen@akad.med.uni-giessen.de ")]),_c('h5',{staticClass:"text-h5 pt-5"},[_c('strong',[_vm._v(_vm._s(_vm.$t("impressum.represented-by")))])]),_c('p',[_vm._v(" Dr. med. Achim Michel-Backofen ")]),_c('h5',{staticClass:"text-h5"},[_c('strong',[_vm._v(_vm._s(_vm.$t("impressum.responsible-content")))])]),_c('p',[_vm._v(" Dr. med. Achim Michel-Backofen ")]),_c('h3',{class:{
        'text-h5 pt-7': _vm.$vuetify.breakpoint.smAndDown,
        'text-h4 pt-16': _vm.$vuetify.breakpoint.mdAndUp,
      }},[_vm._v(" "+_vm._s(_vm.$t("impressum.disclaimer"))+" ")]),_c('h5',{staticClass:"text-h5 my-4"},[_c('strong',[_vm._v(_vm._s(_vm.$t("impressum.liability-content")))])]),_c('p',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(_vm.$t("impressum.liability-content-desc"))+" ")]),_c('h5',{staticClass:"text-h5 my-4"},[_c('strong',[_vm._v(_vm._s(_vm.$t("impressum.liability-links")))])]),_c('p',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(_vm.$t("impressum.liability-links-desc"))+" ")]),_c('h5',{staticClass:"text-h5 my-4"},[_c('strong',[_vm._v(_vm._s(_vm.$t("impressum.copyright")))])]),_c('p',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(_vm.$t("impressum.copyright-desc"))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }