<template>
  <v-app>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="drawer"
      app
    >
      <v-list class="mt-16 ml-2">
        <v-list-item>
          <v-list-item-title>
            <router-link
              class="text-capitalize text-decoration-none black--text"
              to="/"
              >{{ $t("home.navbar.homepage") }}</router-link
            >
          </v-list-item-title>
        </v-list-item>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
        >
          <template v-slot:activator>
            <v-list-item-title class="text-capitalize">{{
              $t(item.title)
            }}</v-list-item-title>
          </template>
          <v-list-item
            :to="{ name: child.routeName }"
            v-for="child in item.submenus"
            :key="child.submenu"
            class="ml-4"
          >
            <v-list-item-title>
              {{ $t(child.submenu) }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item>
          <v-list-item-title>
            <router-link
              to="/partner"
              class="text-decoration-none black--text text-capitalize"
            >
              Partner
            </router-link>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar flat class="bg-white" app :class="[scrolled ? 'scroll' : '']">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <router-link to="/">
        <v-img
          src="../assets/home/diz-gie.png"
          class="shrink ma-3 mr-7"
        ></v-img>
      </router-link>

      <v-row v-if="$vuetify.breakpoint.mdAndUp" class="d-flex route">
        <v-btn text>
          <router-link
            text
            to="/"
            class="text-decoration-none black--text ma-3 ml-4 text-capitalize"
          >
            {{ $t("home.navbar.homepage") }}
          </router-link>
        </v-btn>
        <v-menu offset-y v-for="item in items" :key="item.title">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" class="text-capitalize">
              {{ $t(item.title) }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :to="{ name: child.routeName }"
              v-for="child in item.submenus"
              :key="child.submenu"
            >
              <v-list-item-title>
                {{ $t(child.submenu) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          text
          to="/partner"
          class="text-decoration-none black--text text-capitalize"
        >
          Partner
        </v-btn>
      </v-row>
      <v-spacer></v-spacer>
      <local-switcher></local-switcher>
      <v-btn text class="text-capitalize" to="/contact">
        <v-icon class="mr-1">mdi-email</v-icon>
        <span>{{ $t("home.navbar.contact") }}</span>
      </v-btn>
    </v-app-bar>
  </v-app>
</template>

<script>
import LocalSwitcher from "./LocalSwitcher";
export default {
  components: {
    LocalSwitcher,
  },
  data() {
    return {
      scrollY: null,
      drawer: false,
      items: [
        {
          title: "home.navbar.thedic",
          submenus: [
            { submenu: "home.dic.aboutus", routeName: "about" },
            { submenu: "home.dic.theteam", routeName: "team" },
            { submenu: "home.dic.projects", routeName: "projects" },
          ],
        },
        {
          title: "home.navbar.services",
          submenus: [
            { submenu: "home.services.ourservices", routeName: "services" },
            {
              submenu: "home.services.quality-management",
              routeName: "quality",
            },
          ],
        },
        {
          title: "home.navbar.datausage",
          submenus: [
            {
              submenu: "home.datausage.patientinfo",
              routeName: "patientInfo",
            },
            {
              submenu: "home.datausage.revocation",
              routeName: "revocation",
            },
            { submenu: "home.datausage.methods", routeName: "methods" },
            { submenu: "home.datausage.uac", routeName: "UAC" },
            {
              submenu: "home.datausage.dzno",
              routeName: "dzno",
            },
            {
              submenu: "home.datausage.appsubmission",
              routeName: "requestSubmission",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.scrollY = window.scrollY;
    });
  },
  computed: {
    scrolled: function() {
      return this.scrollY > 0;
    },
  },
};
</script>
<style scoped>
::v-deep .v-application--wrap {
  min-height: fit-content;
}

.bg-white {
  background-color: white !important;
}

.scroll {
  box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.3) !important;
  border-bottom: 3px solid #7eba24 !important;
}
</style>
