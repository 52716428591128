<template>
  <v-container>
    <div class="text-page-border">
      <section>
        <h1
          class="jlu-blue my-5"
          :class="{
            'text-h5': $vuetify.breakpoint.smAndDown,
            'text-h3': $vuetify.breakpoint.mdAndUp,
          }"
        >
          {{ $t("DZNO.title") }}
        </h1>
        <p class="text-justify">
          <span v-html="$t('DZNO.desc')"></span>
        </p>
      </section>
      <section>
        <v-row>
          <v-col>
            <download-card
              :title="$t('DZNO.card-title-mii')"
              version="V1.1"
              releaseDate="17.12.2020"
              href="/docs/MII_Nutzungsordnung_v1.1_20201217.pdf"
            ></download-card>
          </v-col>
          <v-col>
            <download-card
              :title="$t('DZNO.card-title-local')"
              version="V1.0"
              :disabled="true"
              releaseDate="TT.MM.YYYY"
            ></download-card>
          </v-col>
        </v-row>
      </section>
    </div>
  </v-container>
</template>
<script>
export default {};
</script>
