<template>
  <v-btn
    fixed
    right
    bottom
    style="background-color: #7eba24"
    v-show="scY > 150"
    @click="toTopWindow"
  >
    <v-icon>mdi-arrow-up</v-icon>
  </v-btn>
</template>
<script>
export default {
  data() {
    return {
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function() {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTopWindow: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
