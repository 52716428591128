<template>
  <div>
    <FooterSpacer v-if="route.name !== 'home'" />
    <v-footer color="white">
      <v-col>
        <v-row class="footer">
          <v-col class="my-7">
            <v-row
              ><v-icon class="mr-3">mdi-home-city</v-icon
              ><strong>{{ $t("home.dicfooter") }}</strong></v-row
            >
            <v-row>
              <v-icon class="mr-3">mdi-office-building-marker</v-icon
              >Rudolf-Buchheim-Str. 6, 35392 Gießen</v-row
            >
            <v-row>
              <v-icon class="mr-3">mdi-email</v-icon
              ><a
                class="text-decoration-none"
                href="mailto:diz@informatik.med.uni-giessen.de"
                >diz@informatik.med.uni-giessen.de</a
              ></v-row
            >
          </v-col>
          <v-img
            :max-width="$t('home.bmfb') === 'bmfb_EN' ? '270' : '250'"
            :src="require(`../assets/home/${$t('home.bmfb')}.png`)"
          ></v-img>
        </v-row>
        <v-row class="text-center">
          <v-col
            >© 2023 |
            <router-link to="/impressum" class="text-decoration-none">
              {{ $t("home.impressum") }}
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-footer>
    <top-scroller></top-scroller>
  </div>
</template>
<script>
import FooterSpacer from "./FooterSpacer.vue";
export default {
  components: { FooterSpacer },
  computed: {
    /**
     * the currently viewed route
     *
     * @returns {Boolean}
     */
    route: function() {
      return this.$router.app._route;
    },
  },
};
</script>
