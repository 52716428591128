<template>
  <v-card
    :href="href"
    target="_blank"
    :disabled="disabled"
    elevation="5"
    width="510"
    height="290"
    class="mx-auto my-6"
  >
    <v-card-actions>
      <v-icon class="mx-auto mt-6" size="130">mdi-file-download</v-icon>
    </v-card-actions>
    <v-list-item>
      <v-list-item-content class="mx-auto text-center">
        <v-list-item-title class="text-h5">
          {{ title }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-h6">{{
          version
        }}</v-list-item-subtitle>
        <v-list-item-subtitle class="text-h6">{{
          releaseDate
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
export default {
  props: {
    title: String,
    version: String,
    releaseDate: String,
    disabled: Boolean,
    href: String,
  },
  methods: {
    openDoc: function() {},
  },
};
</script>
