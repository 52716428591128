<template>
  <v-container>
    <section class="text-page-border">
      <h1
        class="jlu-blue my-5"
        :class="{
          'text-h4': $vuetify.breakpoint.smAndDown,
          'text-h3': $vuetify.breakpoint.mdAndUp,
        }"
      >
        {{ $t("home.navbar.contact") }}
      </h1>
      <p class="text-justify">
        {{ $t("contact.question") }}
        <a
          class="text-decoration-none"
          href="mailto:diz@informatik.med.uni-giessen.de"
          >diz@informatik.med.uni-giessen.de</a
        >.
      </p>
      <br />
      <br />
      <p>
        <strong>{{ $t("contact.application-processed") }}</strong>
      </p>
      <ul>
        <li v-for="item in items" :key="item.name">{{ $t(item.name) }}</li>
      </ul>
    </section>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      items: [
        { name: "contact.application" },
        { name: "contact.research-data" },
        { name: "contact.data-knowledge" },
        { name: "contact.DIP" },
        { name: "contact.patient-consent" },
        { name: "contact.consulting" },
        { name: "contact.management" },
        { name: "contact.other" },
      ],
    };
  },
};
</script>
