<template>
  <v-container>
    <section class="text-page-border">
      <h1
        class="error--text my-5"
        :class="{
          'text-h5': $vuetify.breakpoint.smAndDown,
          'text-h3': $vuetify.breakpoint.mdAndUp,
        }"
      >
        {{ $t("impressum.title") }}
      </h1>
      <h1
        :class="{
          'text-h4': $vuetify.breakpoint.smAndDown,
          'text-h3': $vuetify.breakpoint.mdAndUp,
        }"
      >
        {{ $t("impressum.disclosure") }}
      </h1>
      <h5 class="text-h4 pt-10">
        {{ $t("impressum.created-by") }}
      </h5>
      <p>
        Sascha Seibel
        <br />
        {{ $t("impressum.IMI") }}
      </p>
      <!--
          Infortmationen Verantwortlichkeit
      -->
      <h3
        :class="{
          'text-h5 pt-7': $vuetify.breakpoint.smAndDown,
          'text-h4 pt-16': $vuetify.breakpoint.mdAndUp,
        }"
      >
        {{ $t("home.dicfooter") }}
      </h3>
      <p>c/o {{ $t("impressum.IMI") }}</p>
      <p><strong>Tel.</strong> +49 (0) 641 99 41393</p>
      <p>
        <strong>E-Mail:</strong> Achim.Michel-Backofen@akad.med.uni-giessen.de
      </p>
      <h5 class="text-h5 pt-5">
        <strong>{{ $t("impressum.represented-by") }}</strong>
      </h5>
      <p>
        Dr. med. Achim Michel-Backofen
      </p>
      <h5 class="text-h5">
        <strong>{{ $t("impressum.responsible-content") }}</strong>
      </h5>
      <p>
        Dr. med. Achim Michel-Backofen
      </p>
      <!--
          Haftungsausschluss
      -->
      <h3
        :class="{
          'text-h5 pt-7': $vuetify.breakpoint.smAndDown,
          'text-h4 pt-16': $vuetify.breakpoint.mdAndUp,
        }"
      >
        {{ $t("impressum.disclaimer") }}
      </h3>
      <h5 class="text-h5 my-4">
        <strong>{{ $t("impressum.liability-content") }}</strong>
      </h5>
      <p class="text-justify">
        {{ $t("impressum.liability-content-desc") }}
      </p>
      <h5 class="text-h5 my-4">
        <strong>{{ $t("impressum.liability-links") }}</strong>
      </h5>
      <p class="text-justify">
        {{ $t("impressum.liability-links-desc") }}
      </p>
      <h5 class="text-h5 my-4">
        <strong>{{ $t("impressum.copyright") }}</strong>
      </h5>
      <p class="text-justify">
        {{ $t("impressum.copyright-desc") }}
      </p>
    </section>
  </v-container>
</template>
<script>
export default {};
</script>
