<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text class="padding-zero">
        <country-flag
          :country="$i18n.locale === 'de' ? 'de' : 'us'"
          class="margin-zero"
        />
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(locale, index) in locales"
        :key="index"
        @click="changeLocale(locale.value)"
      >
        <country-flag :country="locale.flag" class="margin-zero" />{{
          locale.name
        }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  data() {
    return {
      locales: [
        { name: "Deutsch", value: "de", flag: "de" },
        { name: "English", value: "en", flag: "us" },
      ],
    };
  },
  methods: {
    /**
     * Updtae the language of the App
     *
     * @param {string} locale is the language that is being set as new language
     */
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>
