<template>
  <v-app>
    <NavbarComponent />
    <v-main class="mx-4 mb-4">
      <router-view />
    </v-main>
    <PageFooter />
  </v-app>
</template>

<script>
import NavbarComponent from "./components/NavbarComponent.vue";
import PageFooter from "./components/PageFooter.vue";
export default {
  name: "App",
  components: { NavbarComponent, PageFooter },
};
</script>
<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.jlu-blue {
  color: rgb(22, 90, 151);
}

.dip-green {
  color: #7eba24;
}

.text-page-border {
  padding: 0 43px;
  margin-left: 66px;
  margin-right: 66px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-left: 0px;
    margin-right: 0px;
    border-left: 0px;
    border-right: 0px;
    padding: 0 15px;
    padding-bottom: 10px !important;
  }
  padding-bottom: 20px;
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
}

.footer {
  margin: 0 125px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: grid;
    margin: 0;
    place-content: center;
    padding: 0 !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.margin-zero {
  margin: 0 !important;
}

.padding-zero {
  padding: 0 !important;
}
</style>
