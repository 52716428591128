<template>
  <v-container>
    <div class="text-h3 text-center my-5">{{ $t("team.our-team") }}</div>
    <v-row row wrap>
      <v-col cols="12" md="6" xl="4" v-for="team in teams" :key="team.name">
        <v-card elevation="6" max-width="380" outlined class="ma-5 mx-auto">
          <v-list-item :class="`${team.shortcut}`">
            <v-list-item-avatar
              class="mx-auto my-6"
              width="120px"
              height="120px"
            >
              <v-img
                :src="require(`../../assets/team/${team.pic}.png`)"
              ></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
                <div v-if="team.detail" class="text-overline mb-4 text-center">
                  {{ $t(team.type) }} <br> {{ $t(team.detail) }}
                </div>
              <div v-else class="text-overline mb-4 text-center">
                {{ $t(team.type) }}
              </div>
              <v-list-item-title class="text-h5 mb-1">
                {{ team.name }}
              </v-list-item-title>
              <v-list-item-subtitle><a :href="'mailto:' + team.email">{{ team.email }}</a></v-list-item-subtitle>
              <v-list-item-subtitle v-if="team.tel">Tel. {{ team.tel }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else><br></v-list-item-subtitle>
              <v-list-item-subtitle v-if="team.room"
                >{{ $t("team.room") }} {{ team.room }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else> <br> </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      teams: [
        // Operative representatives
        {
          name: "Prof. Dr. Till Acker",
          tel: "+49 641 99 41181",
          email: "Till.Acker@patho.med.uni-giessen.de",
          type: "team.sci-man",
          shortcut: "sci-man",
          pic: "avatar",
        },
        {
          name: "Prof. Dr. Henning Schneider",
          tel: "+49 641 99 41360",
          room: "1.102",
          email: "Henning.Schneider@informatik.med.uni-giessen.de",
          type: "team.sci-man",
          shortcut: "sci-man",
          pic: "avatar",
        },
        {
          name: "Prof. Dr. Kurt Marquardt",
          email: "Kurt.Marquardt@informatik.med.uni-giessen.de",
          type: "team.sci-man",
          shortcut: "sci-man",
          pic: "avatar",
        },

        // Core Team
        {
          name: "Romina Blasini",
          tel: "+49 641 99 41386",
          room: "2.210",
          email: "Romina.Blasini@informatik.med.uni-giessen.de",
          type: "team.core-team",
          detail: "team.dic-leader",
          shortcut: "core-team",
          pic: "avatar",
        },
        {
          name: "Dr. Achim Michel-Backofen",
          tel: "+49 641 99 41393",
          room: "2.214",
          email: "Achim.Michel-Backofen@akad.med.uni-giessen.de",
          type: "team.core-team",
          detail: "team.transfer-office",
          shortcut: "core-team",
          pic: "Achim",
        },
        {
          name: "Jan Marquardt",
          tel: "+49 641 99 41384",
          room: "2.206",
          email: "Jan.Marquardt@informatik.med.uni-giessen.de",
          type: "team.core-team",
          detail: "team.project-mgt",
          shortcut: "core-team",
          pic: "avatar",
        },
        {
          name: "Jördis Beck",
          tel: "+49 641 99 41385",
          room: "2.207",
          email: "Joerdis.Beck@informatik.med.uni-giessen.de",
          type: "team.core-team",
          detail: "team.research-data",
          shortcut: "core-team",
          pic: "avatar",
        },
        {
          name: "Stefanie Lehn",
          tel: "+49 641 99 41395",
          room: "2.214",
          email: "Stefanie.Lehn@informatik.med.uni-giessen.de",
          type: "team.core-team",
          detail: "team.sof-dev",
          shortcut: "core-team",
          pic: "avatar",
        },
        {
          name: "Julian Schallenberg",
          tel: "+49 641 99 41392",
          room: "2.213",
          email: "Julian.Schallenberg@informatik.med.uni-giessen.de",
          type: "team.core-team",
          detail: "team.sof-dev",
          shortcut: "core-team",
          pic: "avatar",
        },
        {
          name: "Wilfried Hahnl",
          email: "wilfried.hahnl@uniklinikum-giessen.de",
          type: "team.core-team",
          detail: "team.infra",
          shortcut: "core-team",
          pic: "avatar",
        },
        {
          name: "Jannik Sehring",
          tel: "+49 641 99 41186",
          email: "Jannik.Sehring@patho.med.uni-giessen.de",
          type: "team.core-team",
          detail: "team.knowledge-man",
          shortcut: "core-team",
          pic: "avatar",
        },

        // Use Cases - Team
        {
          name: "Dr. Florian Eberhardt",
          tel: "+49 641 99 41388",
          room: "2.211",
          email: "Florian.Eberhardt@informatik.med.uni-giessen.de",
          type: "team.use-case-team",
          shortcut: "use-case-team",
          pic: "avatar",
        },
        {
          name: "Lisa Steinbrecher",
          tel: "+49 641 99 41395",
          room: "2.204",
          email: "Lisa.Steinbrecher@informatik.med.uni-giessen.de",
          type: "team.use-case-team",
          shortcut: "use-case-team",
          pic: "avatar",
        },
        {
          name: "Doris Budasch",
          tel: "+49 641 99 41395",
          room: "2.205",
          email: "Doris.M.Budasch@informatik.med.uni-giessen.de",
          type: "team.use-case-team",
          shortcut: "use-case-team",
          pic: "avatar",
        },
        {
          name: "Nick Heller",
          tel: "+49 641 99 41392",
          room: "2.213",
          email: "Nick.Heller@informatik.med.uni-giessen.de",
          type: "team.use-case-team",
          shortcut: "use-case-team",
          pic: "avatar",
        },
        {
          name: "Christopher Frank",
          tel: "+49 641 99 41384",
          room: "2.205",
          email: "Christopher.Frank@informatik.med.uni-giessen.de",
          type: "team.use-case-team",
          shortcut: "use-case-team",
          pic: "avatar",
        },
        {
          name: "Dr. Jochen Zohner",
          tel: "+49 641 99 41393",
          room: "2.214",
          email: "Jochen.Zohner@akad.med.uni-giessen.de",
          type: "team.use-case-team",
          shortcut: "use-case-team",
          pic: "avatar",
        },
        {
          name: "Aileen Müller",
          tel: "+49 641 99 41393",
          room: "2.214",
          email: "Aileen.Mueller@informatik.med.uni-giessen.de",
          type: "team.use-case-team",
          shortcut: "use-case-team",
          pic: "avatar",
        },
        {
          name: "Kai Schmid",
          tel: "+49 641 99 41186",
          email: "Kai.Schmid@patho.med.uni-giessen.de",
          type: "team.use-case-team",
          shortcut: "use-case-team",
          pic: "avatar",
        },
        {
          name: "Dr. Daniel Amsel",
          tel: "+49 641 99 41185",
          email: "Daniel.Amsel@patho.med.uni-giessen.de",
          type: "team.use-case-team",
          shortcut: "use-case-team",
          pic: "avatar",
        },
        {
          name: "Sarah Pohl",
          tel: "+49 641 99 41384",
          room: "2.206",
          email: "Sarah.Pohl@informatik.med.uni-giessen.de",
          type: "team.use-case-team",
          shortcut: "use-case-team",
          pic: "avatar",
        },
      ],
    };
  },
};
</script>
<style scoped>
.sci-man {
  background: linear-gradient(
    90deg,
    #1d1c78,
    #0046ac,
    #006bcd,
    #008ed7,
    #00afc6,
    #00cea1,
    #00e96e,
    #92ff2b
  );
  clip-path: ellipse(69% 100% at 50.19% 0%);
}

.q-pro {
  background: linear-gradient(
    90deg,
    #45ffe3,
    #00e0e1,
    #00bfda,
    #009fcd,
    #007fb9,
    #005f9f,
    #00407f,
    #1f225c
  );
  clip-path: ellipse(69% 100% at 50.19% 0%);
}

.core-team {
  background: linear-gradient(
    90deg,
    #1f005c,
    #5b0060,
    #870160,
    #ac255e,
    #ca485c,
    #e16b5c,
    #f39060,
    #ffb56b
  );
  clip-path: ellipse(69% 100% at 50.19% 0%);
}

.data-know {
  background: linear-gradient(
    90deg,
    #ff85f9,
    #dd70e2,
    #bc5ccc,
    #9c48b5,
    #7d359e,
    #5e2288,
    #3f1072,
    #1f005c
  );
  clip-path: ellipse(69% 100% at 50.19% 0%);
}

.use-case-team {
  background: linear-gradient(
    90deg,
    #ff2b52,
    #e8005c,
    #ce0063,
    #b00068,
    #91006a,
    #6e0069,
    #4a0064,
    #1f005c
  );
  clip-path: ellipse(69% 100% at 50.19% 0%);
}

.transfer {
  background: linear-gradient(
    90deg,
    #a4ff73,
    #20e792,
    #00cbad,
    #00acbd,
    #008cbc,
    #006aa9,
    #004987,
    #31295c
  );
  clip-path: ellipse(69% 100% at 50.19% 0%);
}

.wi-mira {
  background: linear-gradient(
    90deg,
    #5c1954,
    #722269,
    #882c7f,
    #9f3695,
    #b640ad,
    #ce4bc5,
    #e656de,
    #ff61f7
  );
  clip-path: ellipse(69% 100% at 50.19% 0%);
}
</style>
