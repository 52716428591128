<template>
  <div>
    <v-container>
      <section>
        <h4
          class="jlu-blue my-4"
          :class="{
            'text-h5': $vuetify.breakpoint.smAndDown,
            'text-h3': $vuetify.breakpoint.mdAndUp,
          }"
        >
          {{ $t("application-submission.title") }}
        </h4>
        <p class="text-justify">
          <span v-html="$t('application-submission.desc')"></span>
        </p>
      </section>
    </v-container>
    <v-stepper v-model="slide" flat vertical>
      <template v-for="(method, index) in methods">
        <v-stepper-step
          color="#7eba24"
          :complete="slide > index + 1"
          :step="index + 1"
          :key="index"
          :id="method.id"
          @click="slide = index + 1"
        >
          <div text class="font-weight-medium cursor-pointer ml-4">
            {{ $t(method.step) }}
          </div>
        </v-stepper-step>

        <v-stepper-content :step="index + 1" :key="method.name">
          <v-card elevation="5" class="ma-3" height="100%">
            <v-row class="pa-5 ma-3">
              <v-col class="my-auto">
                <v-img
                  class="mx-auto"
                  :max-width="$vuetify.breakpoint.xs ? '220' : '380'"
                  :src="require(`../../assets/requestSub/${method.img}.png`)"
                ></v-img>
              </v-col>
              <v-col class="d-flex flex-column justify-space-between">
                <v-row class="ma-3">
                  <p class="text-justify">
                    <span v-html="$t(method.desc)"></span>
                  </p>
                </v-row>
                <v-row align="end" class="ma-3">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="text-capitalize"
                    color="#165A97"
                    dark
                    @click="slide -= 1"
                    v-if="slide != 1"
                    >{{ $t("methods.prev-button") }}
                  </v-btn>

                  <v-btn
                    class="ml-3 text-capitalize"
                    color="#7eba24"
                    dark
                    @click="
                      slide += 1;
                      scrollToId(method.id);
                    "
                    v-if="slide != 11"
                  >
                    {{ $t("methods.next-button") }}
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
      </template>
    </v-stepper>
  </div>
</template>
<script>
export default {
  data() {
    return {
      slide: 1,
      methods: [
        {
          step: "application-submission.methods.feasibility-app",
          desc: "application-submission.methods.feasibility-app-desc",
          img: "Machbarkeitsantrag",
          id: 1,
        },
        {
          step: "application-submission.methods.review-by-DIC",
          desc: "application-submission.methods.review-by-DIC-desc",
          img: "begutachtung",
          id: 2,
        },
        {
          step: "application-submission.methods.feasibility-query",
          desc: "application-submission.methods.feasibility-query-desc",
          img: "feasibility_abfrage",
          id: 3,
        },
        {
          step: "application-submission.methods.data-management-plan",
          desc: "application-submission.methods.data-management-plan-desc",
          img: "dmp",
          id: 4,
        },
        {
          step: "application-submission.methods.project-proposal",
          desc: "application-submission.methods.project-proposal-desc",
          img: "projektantrag",
          id: 5,
        },
        {
          step: "application-submission.methods.review-by-UAC",
          desc: "application-submission.methods.review-by-UAC-desc",
          img: "uac-req",
          id: 6,
        },
        {
          step: "application-submission.methods.data-usage-contract",
          desc: "application-submission.methods.data-usage-contract-desc",
          img: "datausagecontract",
          id: 7,
        },
        {
          step: "application-submission.methods.activation-data-sources",
          desc: "application-submission.methods.activation-data-sources-desc",
          img: "dip_landing",
          id: 8,
        },
        {
          step: "application-submission.methods.researcher-produces-results",
          desc:
            "application-submission.methods.researcher-produces-results-desc",
          img: "research",
          id: 9,
        },
        {
          step: "application-submission.methods.feedback-results",
          desc: "application-submission.methods.feedback-results-desc",
          img: "rueckuebermittlung",
          id: 10,
        },
        {
          step: "application-submission.methods.archiving-source-data",
          desc: "application-submission.methods.archiving-source-data-desc",
          img: "archiving",
          id: 11,
        },
      ],
    };
  },
  methods: {
    scrollToId: (id) => {
      document.getElementById(id).scrollIntoView();
    },
  },
};
</script>
