<template>
  <v-stepper v-model="slide" flat vertical>
    <template v-for="(method, index) in methods">
      <v-stepper-step
        color="#7eba24"
        :complete="slide > index + 1"
        :step="index + 1"
        :key="index"
        :id="method.id"
        @click="slide = index + 1"
      >
        <div class="font-weight-medium cursor-pointer ml-4">
          {{ $t(method.step) }}
        </div>
      </v-stepper-step>

      <v-stepper-content :step="index + 1" :key="method.name">
        <v-card elevation="5" class="ma-3" height="100%">
          <v-row class="pa-5 ma-3">
            <v-col class="my-auto">
              <v-img
                class="mx-auto"
                max-width="230"
                :src="require(`../../assets/methods/${method.img}.png`)"
              ></v-img>
            </v-col>
            <v-col class="d-flex flex-column justify-space-between">
              <v-row class="ma-3">
                <p class="text-justify">
                  <span v-html="$t(method.desc)"></span>
                </p>
              </v-row>
              <v-row align="end" class="ma-3">
                <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize"
                  color="#165A97"
                  dark
                  @click="slide -= 1"
                  v-if="slide != 1"
                  >{{ $t("methods.prev-button") }}
                </v-btn>

                <v-btn
                  class="ml-3 text-capitalize"
                  color="#7eba24"
                  dark
                  @click="
                    slide += 1;
                    scrollToId(method.id);
                  "
                  v-if="slide != 12"
                >
                  {{ $t("methods.next-button") }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-stepper-content>
    </template>
  </v-stepper>
</template>
<script>
export default {
  data() {
    return {
      slide: 1,
      methods: [
        {
          step: "methods.research",
          desc: "methods.research-desc",
          img: "Forschung",
          id: 1,
        },
        {
          step: "methods.routine",
          desc: "methods.routine-desc",
          img: "Routine",
          id: 2,
        },
        {
          step: "methods.app-formulation",
          desc: "methods.app-formulation-desc",
          img: "Idee",
          id: 3,
        },
        {
          step: "methods.finished-app",
          desc: "methods.finished-app-desc",
          img: "FertigAntrag",
          id: 4,
        },
        {
          step: "methods.uac",
          desc: "methods.uac-desc",
          img: "UAC",
          id: 5,
        },
        {
          step: "methods.app-approved",
          desc: "methods.app-approved-desc",
          img: "AntragBewilligt",
          id: 6,
        },
        {
          step: "methods.data-management",
          desc: "methods.data-management-desc",
          img: "Durchsuchen",
          id: 7,
        },
        {
          step: "methods.data-export",
          desc: "methods.data-export-desc",
          img: "Pseudonymisierung",
          id: 8,
        },
        {
          step: "methods.visualization",
          desc: "methods.visualization-desc",
          img: "Visualisierung",
          id: 9,
        },
        {
          step: "methods.research-result",
          desc: "methods.research-result-desc",
          img: "Forschungsergebnis",
          id: 10,
        },
        {
          step: "methods.traceability-results",
          desc: "methods.traceability-results-desc",
          img: "Rückführung",
          id: 11,
        },
        {
          step: "methods.improving-patient-care",
          desc: "methods.improving-patient-care-desc",
          img: "Patientenversorgung",
          id: 12,
        },
      ],
    };
  },
  methods: {
    scrollToId: (id) => {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
