<template>
  <v-container>
    <div class="text-page-border">
      <section>
        <h1
          class="jlu-blue my-5"
          :class="{
            'text-h4': $vuetify.breakpoint.smAndDown,
            'text-h3': $vuetify.breakpoint.mdAndUp,
          }"
        >
          Use and Access Committee - UAC
        </h1>
        <p class="text-justify">
          <span>{{ $t('UAC.UAC-desc') }}</span>
           <a href='https://forschen-fuer-gesundheit.de/'>FDPG).</a><br/><br/><a class='text-decoration-none' target='_blank' href='https://mirproskive.med.uni-giessen.de/'><v-btn elevated color="primary">{{ $t('UAC.proskive-apply') }}</v-btn></a>
        </p>
      </section>
      <section>
        <v-img
          class="mx-auto"
          max-width="1300"
          src="../../assets/img/methodes_arrow_uac.png"
        ></v-img>
        <br/> 
        <v-divider></v-divider>
        <div class="text-h3 text-center my-5">{{ $t('UAC.uac-composition') }}</div>
        <v-row row wrap>
          <v-col cols="12" md="6" xl="4" v-for="team in teams" :key="team.id">
            <v-card elevation="6" max-width="380" outlined class="ma-5 mx-auto">
              <v-list-item :class="`${team.shortcut}`">
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                    <div v-if="team.detail" class="text-overline mb-4 text-center font-weight-bold">
                      {{ $t(team.type) }}
                    </div>
                  <div v-else class="text-overline mb-4 text-center">
                    {{ $t(team.type) }}
                  </div>
                  <v-list-item-title class="text-h5 mb-1 text-center">
                    {{ team.name }} <br/>
                  </v-list-item-title>
                  <v-list-item-title class="text-overline mb-4 text-center">{{ $t(team.detail) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <download-card
          :title="$t('UAC.proc-rules')"
          version="V1.0"
          releaseDate="11.01.2021"
          href="/docs/Dokument.10.3.UAC-GO_V1.0_2022-01-11.pdf"
        >
        </download-card>
        <router-link :to="{ name: 'dzno' }" class="text-decoration-none">{{
            $t("UAC.dzno")
          }}</router-link>
        </section>
    </div>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      teams: [
        // Operative representatives
        {
          id: 1,
          name: "Prof. Dr. Gabriele A. Krombach",
          type: "UAC.clinical",
          detail: "UAC.radiology",
          shortcut: "sci-man",
        },
        {
          id: 2,
          name: "Prof. Dr. Florian Wagenlehner",
          type: "UAC.clinical",
          detail: "UAC.urology",
          shortcut: "sci-man",
        },
        {
          id: 3,
          name: "Prof. Dr. Andreas Meinhardt",
          type: "UAC.preclinical",
          detail: "UAC.anatomy-cell-biology",
          shortcut: "sci-man"
        },
        {
          id: 4,
          name: "Prof. Dr. Bernd Wöstmann",
          type: "UAC.dental-medicine",
          detail: "UAC.prosthodontics",
          shortcut: "sci-man",
        },
        {
          id: 5,
          name: "Prof. Dr. Soni Pullamsetti",
          type: "UAC.research-professorships",
          detail: "UAC.lung-vascular-epigenetics",
          shortcut: "sci-man",
        },
        {
          id: 6,
          name: "Prof. Dr. Andreas Günther",
          type: "UAC.leader-biobank",
          detail: "UAC.pneumology",
          shortcut: "sci-man",
        },
        {
          id: 7,
          name: "Prof. Dr. Keywan Sohrabi",
          type: "UAC.imi",
          detail: "UAC.medical-informatics",
          shortcut: "sci-man",
        },
        {
          id: 8,
          name: "Prof. Dr. Wolfgang Weidner",
          type: "UAC.medical-faculty",
          detail: "UAC.medical-dean",
          shortcut: "sci-man",
        },
        {
          id: 9,
          name: "Dr. Christiane Hinck-Kneip",
          type: "UAC.management-ukgm",
          detail: "UAC.commercial-management",
          shortcut: "sci-man",
        },
        {
          id: 10,
          name: "Prof. Dr. Till Acker",
          type: "UAC.sci-man",
          detail: "UAC.vice-dean-2",
          shortcut: "sci-man",
        },
        {
          id: 11,
          name: "Prof. Dr. Kurt Marquardt",
          type: "UAC.sci-man",
          detail: "UAC.scientific-it-projects",
          shortcut: "sci-man",
        },
        {
          id: 12,
          name: "Prof. Dr. Henning Schneider",
          type: "UAC.sci-man",
          detail: "UAC.medical-informatics",
          shortcut: "sci-man",
        },
        {
          id: 13,
          name: "Romina Blasini",
          type: "UAC.dic-leader",
          detail: "UAC.dic-giessen",
          shortcut: "sci-man",
        },
        {
          id: 14,
          name: "Dr. Florian Eberhardt",
          type: "UAC.vice-dic-leader",
          detail:"UAC.dic-giessen",
          shortcut: "sci-man",
        },
      ],
    };
  },
};
</script>
<style scoped>
.sci-man {
  background: linear-gradient(90deg,
      #1d1c78,
      #0046ac,
      #006bcd,
      #008ed7,
      #00afc6,
      #00cea1,
      #00e96e,
      #92ff2b);
  clip-path: ellipse(69% 100% at 50.19% 0%);
}

.q-pro {
  background: linear-gradient(90deg,
      #45ffe3,
      #00e0e1,
      #00bfda,
      #009fcd,
      #007fb9,
      #005f9f,
      #00407f,
      #1f225c);
  clip-path: ellipse(69% 100% at 50.19% 0%);
}

.core-team {
  background: linear-gradient(90deg,
      #1f005c,
      #5b0060,
      #870160,
      #ac255e,
      #ca485c,
      #e16b5c,
      #f39060,
      #ffb56b);
  clip-path: ellipse(69% 100% at 50.19% 0%);
}

.data-know {
  background: linear-gradient(90deg,
      #ff85f9,
      #dd70e2,
      #bc5ccc,
      #9c48b5,
      #7d359e,
      #5e2288,
      #3f1072,
      #1f005c);
  clip-path: ellipse(69% 100% at 50.19% 0%);
}

.use-case-team {
  background: linear-gradient(90deg,
      #ff2b52,
      #e8005c,
      #ce0063,
      #b00068,
      #91006a,
      #6e0069,
      #4a0064,
      #1f005c);
  clip-path: ellipse(69% 100% at 50.19% 0%);
}

.transfer {
  background: linear-gradient(90deg,
      #a4ff73,
      #20e792,
      #00cbad,
      #00acbd,
      #008cbc,
      #006aa9,
      #004987,
      #31295c);
  clip-path: ellipse(69% 100% at 50.19% 0%);
}

.wi-mira {
  background: linear-gradient(90deg,
      #5c1954,
      #722269,
      #882c7f,
      #9f3695,
      #b640ad,
      #ce4bc5,
      #e656de,
      #ff61f7);
  clip-path: ellipse(69% 100% at 50.19% 0%);
}
</style>
