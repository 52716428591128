<template>
  <main>
    <section class="hero d-flex items-center">
      <v-flex>
        <v-main class="title" :class="{ 'text-h4': $vuetify.breakpoint.xs }">
          <span>{{ $t("home.sign.data") }}</span>
          <span>{{ $t("home.sign.integration") }}</span>
          <span>{{ $t("home.sign.center") }}</span>
        </v-main>
        <v-main class="content">
          <p class="mt-2 text-justify">
            {{ $t("home.desc") }}
          </p>
        </v-main>
      </v-flex>
      <v-row class="hero-img flex-1"> </v-row>
    </section>
    <section>
      <v-row
        :class="
          $vuetify.breakpoint.xs
            ? 'py-7 img-section my-7'
            : 'py-16 img-section my-7'
        "
      >
        <v-col>
          <a href="http://dip.med.uni-giessen.de/" target="blank"
            ><v-img
              :max-width="$vuetify.breakpoint.xs ? 250 : 320"
              src="../../assets/home/dip-logo-home.png"
              class="mt-6 mx-auto"
            ></v-img
          ></a>
        </v-col>
        <v-col>
          <a href="https://www.medizininformatik-initiative.de/" target="blank"
            ><v-img
              :max-width="$vuetify.breakpoint.xs ? 250 : 320"
              src="../../assets/home/Logo_MII_home.png"
              class="mx-auto"
            ></v-img
          ></a>
        </v-col>
      </v-row>
    </section>
  </main>
</template>
<script>
export default {};
</script>
<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";
.hero {
  padding: 0 35px;
  margin-left: 125px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-left: 0px;
    border-left: 0px;
    padding-bottom: 10px !important;
    height: 100%;
  }
  padding-top: 85px;
  padding-bottom: 162px;
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  height: calc(100vh - 5rem);
  .title {
    padding: 0 !important;

    :nth-child(1) {
      color: #165a97;
    }
    :nth-child(2) {
      color: #7eba24;
    }
    :nth-child(3) {
      color: #000000;
    }
    span {
      display: block;
      font-weight: 600;
      font-size: 45px;
      line-height: 52px;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        font-size: 35px;
      }
    }
  }
  .content {
    font-size: 22px;
    max-width: 620px !important;
    padding-top: 25px !important;
    @media #{map-get($display-breakpoints, 'xl-only')} {
      max-width: 783px !important;
    }
  }

  .hero-img {
    height: 296px;
    position: absolute;
    right: 20px;
    left: 50%;
    background-image: url("../../assets/home/hero.png");
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      left: 30%;
    }
    object-fit: cover;
  }
}
.img-section {
  background: #eaeff7;
}

.img-second {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 160px;
    object-fit: cover;
  }
}
</style>
